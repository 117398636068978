import './App.css';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Layout from './Pages/Layout';
import Main from './Pages/Main';
import TrackShipment from './Pages/TrackShipment';
import TrackShipmentDetails from './Pages/TrackShipmentDetails';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import RefundPolicy from './Pages/RefundPolicy';
import TermsConditions from './Pages/TermsConditions';
import BlogPage from './Pages/BlogPage';
import Aboutus from './Pages/AboutUs';
import Blogpost from './Pages/BlogPost';
import Features from './Pages/Features';
import ContactUs from './Pages/ContactUs';

function App() {
  return (
   <React.Fragment>
    <Routes>
      <Route path='/' element={<Layout children={<Main />}/>} exact/>
      <Route path='/track-shipment' element={<Layout children={<TrackShipment />} />} exact />
      <Route path='/track-shipment/:trackAwb' element={<Layout children={<TrackShipmentDetails />} />} exact /> 
      <Route path='/privacy-policy' element={<Layout children={<PrivacyPolicy />} />} exact />
      <Route path='refund-policy' element={<Layout children={<RefundPolicy />} />} exact />
      <Route path='/terms-and-conditions' element={<Layout children={<TermsConditions />} />} exact />
      <Route path='/blogs' element={<Layout children={<BlogPage />} />} exact />
      <Route path='/about-us' element={<Layout children={<Aboutus />} />} exact />
      <Route path='/blogs/:id' element={<Layout children={<Blogpost />} />} exact />
      <Route path='/features' element={<Layout children={<Features />} />} exact /> 
      <Route path='/contact-us' element={<Layout children={<ContactUs />} />} exact />
    </Routes>
   </React.Fragment>
  );
}

export default App;
